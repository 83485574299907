import './App.css';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Masro Solutions SRL
        </h1>
        <h5>Simple solutions to complex problems.</h5>
      </header>
      <footer className="App-footer">
        <h5>© 2021 Masro Solutions SRL | VAT BE 0765.621.691</h5>
      </footer>
    </div>
  );
}

export default App;
